var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height fill-width overflow-hidden" },
    [
      [
        _c(
          "v-btn",
          {
            staticClass: "mr-2",
            attrs: { depressed: "", tile: "" },
            on: { click: _vm.testGetMenu },
          },
          [_vm._v(" test get menu ")]
        ),
        _c(
          "v-btn",
          {
            staticClass: "mr-2",
            attrs: { depressed: "", tile: "" },
            on: { click: _vm.testGetInfo },
          },
          [_vm._v(" test get info ")]
        ),
      ],
      _c("pre", [_vm._v(_vm._s(_vm.info))]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }